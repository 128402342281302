<template>
  <content-container
    description="View plan level data, and perform actions on any row."
    class="data-plans"
  >
    <template v-slot:action>
      <mds-button
        id="download-files-plan-action"
        variation="flat"
        icon="download"
        type="button"
        @click="openDownloadPopover"
      >
        Download
      </mds-button>
      <mds-popover
        v-model="displayDownloadPopover"
        position="bottom-left"
        title="Download files"
        title-hidden
        triggered-by="download-files-plan-action"
        width="200px"
        class="download-popover"
      >
        <mds-button
          variation="flat"
          icon="download"
          type="button"
          class="operations-historical-detail__popover-action"
          @click="downloadPlansData()"
        >
          Plan Level
        </mds-button>
        <mds-button
          variation="flat"
          icon="download"
          type="button"
          class="operations-historical-detail__popover-action"
          @click="downloadPlansData('FUNDS')"
        >
          Fund Level
        </mds-button>
        <mds-button
          variation="flat"
          icon="download"
          type="button"
          class="operations-historical-detail__popover-action"
          @click="downloadPlansData('COMPLIANCE')"
        >
          Compliance Level
        </mds-button>
      </mds-popover>
      <mds-search-field
        v-model="searchedPlanId"
        class="data-plans__search-field"
        label="Search by Plan ID"
        placeholder="Search by Plan ID"
        @keyup.enter.native="handleSearch"
        @mds-search-field-input-cleared="handleSearch"
      />
    </template>
    <mds-section
      class="data-plans__filters-section"
      :size="6"
      collapsible
      container
      title="Filters"
      bold
      expanded
    >
      <template v-slot:mds-section-actions>
        <mds-button
          variation="flat"
          icon="refresh"
          class="data-plans__btn-clear"
          @click="clearFilters"
        >
          Clear Filters
        </mds-button>
      </template>
      <mds-loader v-if="filtersLoading" />
      <mds-form
        v-else
        class="data-plans__filters"
        @submit.native.prevent="toggleQuickLinkModal()"
      >
        <mds-fieldset>
          <mds-fieldset horizontal>
            <mds-combo-box
              v-model="selectedFiduciary"
              label="Fiduciary:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterFiduciaryOptions"
              :multiple-item-limit="2"
            />
            <mds-combo-box
              v-model="selectedProvider"
              label="Provider:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterProviderOptions"
              :multiple-item-limit="2"
            />
            <mds-combo-box
              v-model="selectedProgram"
              label="Program:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterProgramOptions"
              :multiple-item-limit="2"
            />
            <mds-combo-box
              v-model="selectedProduct"
              label="Product:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterProductOptions"
              :multiple-item-limit="2"
            />
          </mds-fieldset>
          <mds-fieldset horizontal>
            <mds-combo-box
              v-model="selectedMenu"
              label="Menu:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterMenuOptions"
              :multiple-item-limit="2"
            />
            <mds-combo-box
              v-model="selectedStatus"
              label="Plan Status:"
              multiple
              :placeholder="filtersPlaceholder"
              :data-set="filterStatusOptions"
              :multiple-item-limit="2"
            />
            <mds-date-picker
              v-model="selectedEndDate"
              label="End Date:"
              format="m/d/Y"
            />
            <mds-date-picker
              v-model="selectedUpdateRange"
              label="Updated Range:"
              format="m/d/Y"
              max-date="today"
              mode="range"
            />
          </mds-fieldset>
          <div class="data-plans__save-filters">
            <p>Save this set of filters to add it to the Quick Links section of the Dashboard.</p>
            <mds-button
              variation="primary"
            >
              Save Filters
            </mds-button>
          </div>
        </mds-fieldset>
      </mds-form>
    </mds-section>
    <div class="data-plans__actions">
      <mds-button-container right-aligned>
        <mds-button
          id="additional-data-points-action"
          icon-right="caret-down"
          variation="flat"
          @click="displayAdditionalDataPointsPopover = !displayAdditionalDataPointsPopover"
        >
          Additional Data Points
        </mds-button>
        <mds-popover
          v-model="displayAdditionalDataPointsPopover"
          width="200px"
          position="bottom-left"
          title="Choose additional data points to display"
          title-hidden
          triggered-by="additional-data-points-action"
          class="data-plans__additional-data-points"
        >
          <mds-form>
            <mds-fieldset variation="checkbox-group">
              <mds-checkbox
                v-for="option in filteredAdditionalDataPoints"
                :key="option.key"
                v-model="option.selected"
              >
                {{ option.name }}
              </mds-checkbox>
            </mds-fieldset>
          </mds-form>
        </mds-popover>
      </mds-button-container>
    </div>
    <fade-transition>
      <mds-loader v-if="plansLoading" />
      <!-- Include some error state -->
      <lazy-table
        v-else-if="tableDataValidation"
        :offset="500"
        :header-configs="tableHeaders"
        :row-data="tableData"
        :fixed-first-col="true"
        :pagination-config="tablePaginationConfig"
        pagination="below"
        class="data-plans__table"
        @mds-data-table-page-change="handleTablePageChange"
        @toggle-action-popover="toggleActionButtonPopover($event)"
      />
      <mds-empty-state
        v-else-if="emptyStateValidation"
        title="No Plans Found"
        message="There are no plans that meet your search criteria."
        size="large"
        class="data-plans__empty"
      >
        <template v-slot:mds-empty-state-icon>
          <img :src="`${publicPath}images/icons/empty-search.svg`">
        </template>
        <template v-slot:mds-empty-state-actions>
          <mds-button
            variation="primary"
            type="button"
            @click="clearFilters"
          >
            Search Again
          </mds-button>
        </template>
      </mds-empty-state>
    </fade-transition>
    <!--
      One single popover created outside MDS data-table to avoid create dynamically multiple popover instances.
      This takes automatically its position based on their respective button ID, which is retrieved from triggerPopover data prop.
    -->
    <mds-custom-popover
      ref="popoverRef"
      :triggered-by="triggerPopover"
      position="bottom-right"
      title="View Plan Details"
      title-hidden
      width="200px"
      class="data-plans-popover"
    >
      <a
        :href="$router.resolve({
          name: 'data-plans-detail',
          params: {
            id: activeCellData.rowData.planTableId
          },
          query: {
            provider: activeCellData.rowData.planProvider,
          }
        }).href"
      >
        View Plan Details
      </a>
    </mds-custom-popover>
    <dashboard-modal-save
      v-model="inputNewQuickLinkName"
      :toggle-modal="toggleModal"
      :disable-save-btn="disableQuickLinkSaveBtn"
      title="Create Quick Link Name"
      description="Save this set of filters to add it to the Quick Links section of the Dashboard."
      @close-modal="closeNewQuickLinkModal"
      @action-modal-button="createNewQuickLink"
    />
  </content-container>
</template>

<script>
import ContentContainer from '../../components/ContentContainer/ContentContainer'
import FadeTransition from '../../components/Transitions/FadeTransition'
import LazyTable from '../../components/LazyTable/LazyTable'
import { MdsButtonContainer, MdsButton } from '@mds/button'
import MdsCheckbox from '@mds/checkbox'
import MdsEmptyState from '@mds/empty-state'
import MdsFieldset from '@mds/fieldset'
import MdsComboBox from '@mds/combo-box'
import MdsForm from '@mds/form'
import MdsLoader from '@mds/loader'
import MdsPopover from '@mds/popover'
import { isEmpty } from 'lodash'
import MdsCustomPopover from '../../components/Popover/Popover'
import MdsSearchField from '@mds/search-field'
import MdsDatePicker from '../../components/Form/MdsDatePicker/MdsDatePicker'
import sessionStorageMixin from '../../mixins/session-storage'
import { formatDateFromIsoString, formatIsoFromDateString, generateRandomId } from '../../../shared/utils'
import { DATE_FORMAT } from '../../../shared/constants/dates'
import { PAGE_FILTERS_MAP, DATA_PLANS_NAME } from '../../../shared/constants/filters'
import { CACHE_FIRST } from '../../graqhql/fetch-policies'
import RoleAccess from '../../services/role-access-service'
import { RadarViews } from '../../../shared/constants/radar-views'
import MdsSection from '@mds/section'
import DashboardModalSave from '../../components/DashboardModalSave/DashboardModalSave'
import { CREATE_DASHBOARD_QUICK_LINK } from '../../graqhql/mutations'
import {
  GET_FIDUCIARIES,
  GET_PROGRAMS,
  GET_PROVIDERS,
  GET_LIVE_PROVIDERS,
  GET_PRODUCTS,
  GET_MENUS,
  GET_PLAN_STATUSES,
  GET_PLANS,
  GET_PLANS_COUNT,
  GET_DASHBOARD_USER_ID,
} from '../../graqhql/queries'

const ColumnDisplayStates = {
  ALWAYS: 'ALWAYS', // A required column that should always be displayed.
  BY_DEFAULT: 'BY_DEFAULT', // A column that is initially displayed but can then be turned off.
  NOT_BY_DEFAULT: 'NOT_BY_DEFAULT', // A column that is not initially display but can then be turned on.
}

const PLAN_TABLE_COLUMN_CONFIG = [
  {
    id: 'action',
    name: 'Action',
    width: '50px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'planId',
    name: 'Plan ID',
    width: '190px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'planName',
    name: 'Plan Name',
    width: '250px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'planProgram',
    name: 'Program',
    width: '100px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'planProduct',
    name: 'Product',
    width: '190px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'planMenu',
    name: 'Menu',
    width: '170px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'planProvider',
    name: 'Provider',
    width: '170px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'planFiduciary',
    name: 'Fiduciary',
    width: '150px',
    display: ColumnDisplayStates.ALWAYS,
  },
  {
    id: 'planStartDate',
    name: 'Start Date',
    width: '190px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'planEndDate',
    name: 'End Date',
    width: '190px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'planStatus',
    name: 'Plan Status',
    width: '100px',
    display: ColumnDisplayStates.BY_DEFAULT,
  },
  {
    id: 'planProcessedDate',
    name: 'Processed Date',
    width: '170px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
  {
    id: 'planFundedDate',
    name: 'Funded Date',
    width: '170px',
    display: ColumnDisplayStates.NOT_BY_DEFAULT,
  },
]

const initializeAdditionalDataPointConfig = (config) => {
  return config
    .filter(({ display }) => display !== ColumnDisplayStates.ALWAYS)
    .map(({ id, name, display }) => ({ id, name, selected: display === ColumnDisplayStates.BY_DEFAULT }))
}

// Filter/search constants
const PAGE_NAME = 'data-plans'
const INITIAL_USER_ID = null
const INITIAL_SELECTED_FIDUCIARY = ['Morningstar']
const EMPTY_SELECTED_FIDUCIARY = []
const INITIAL_SELECTED_PROVIDER = null
const EMPTY_SELECTED_PROVIDER = []
const INITIAL_SELECTED_PROGRAM = []
const INITIAL_SELECTED_PRODUCT = []
const INITIAL_SELECTED_MENU = []
const INITIAL_SELECTED_STATUS = []
const INITIAL_SELECTED_END_DATE = ''
const INITIAL_SELECTED_UPDATED_RANGE = []
const INITIAL_SEARCHED_PLAN_ID = ''
const ALL_PLACEHOLDER = 'All'
const INITIAL_TRIGGER_POPOVER_HANDLER = ''
const EMPTY_NEW_QUICK_LINK_NAME = ''
const INITIAL_ACTIVE_CELL_DATA = {
  rowData: {
    fileType: '',
    provider: '',
  },
}

// Pagination constants
const NUMBER_OF_ROWS_PER_PAGE_OPTIONS = [10, 20, 40, 80]
const NUMBER_OF_ROWS_PER_PAGE_DEFAULT = 20

// CSS custom property constants
const BACKGROUND_COLOR_VAR = 'var(--color--row-background-secondary)'

// Enums
const DataStates = {
  PLAN: 'PLAN',
}

export default {
  components: {
    ContentContainer,
    FadeTransition,
    LazyTable,
    MdsButtonContainer,
    MdsButton,
    MdsCheckbox,
    MdsEmptyState,
    MdsFieldset,
    MdsComboBox,
    MdsForm,
    MdsLoader,
    MdsPopover,
    MdsCustomPopover,
    MdsSearchField,
    MdsDatePicker,
    MdsSection,
    DashboardModalSave,
  },
  mixins: [
    sessionStorageMixin({
      properties: PAGE_FILTERS_MAP[DATA_PLANS_NAME],
      suffix: DATA_PLANS_NAME,
    }),
  ],
  apollo: {
    userId: {
      query: GET_DASHBOARD_USER_ID,
      fetchPolicy: CACHE_FIRST,
    },
    fiduciaries: GET_FIDUCIARIES,
    providers: {
      query: GET_PROVIDERS,
      variables () {
        return {
          selectedView: this.selectedView,
        }
      },
      error (error) {
        this.handleUnauthorizedError(error)
      },
    },
    programs: GET_PROGRAMS,
    products: GET_PRODUCTS,
    menus: GET_MENUS,
    planStatuses: GET_PLAN_STATUSES,
    plansData: {
      query: GET_PLANS,
      variables () {
        return {
          filters: this.plansFilters,
          pagination: this.plansPagination,
        }
      },
      update: data => data.plans,
      result () {
        /**
         * The BE API endpoint for plan data is paginated which means we only request a "slice" of data at a time.
         * As a small performance improvement, we can load sibling pages behind-the-scences each time we navigate
         * to a new page and store them in the cache. This allows for the user to navigate between pages without
         * ever seeing a loader. Fortunately, Apollo handles whether or not it should grab data from the cache or
         * go to the network to fetch data that hasn't already been cached.
         *
         * The data properties "plansDataNextPage" and "plansDataPrevPage" below aren't actually read from. They
         * are only used so Apollo can set the data somewhere.
         */
        this.fetchAdditonalPlansDataPages()
      },
      skip () {
        return this.filtersLoading
      },
      error () {
        this.errorLoadingData = true
        // I don't know why this fires THREE times 😔
        // Should hopefully be resolved in the next version of Vue Apollo.
        // Temporary. Still need to define how we handle errors on the UI.
        this.$notifications.error({ text: 'There was an issue fetching plans. Please try again.', tinted: true, persistent: true })
      },
      fetchPolicy: CACHE_FIRST,
    },
    plansCountData: {
      query: GET_PLANS_COUNT,
      variables () {
        return {
          filters: this.plansFilters,
        }
      },
      update: data => data.plansCount,
      skip () {
        return this.filtersLoading
      },
      error () {
        this.errorLoadingData = true
        // I don't know why this fires THREE times 😔
        // Should hopefully be resolved in the next version of Vue Apollo.
        // Temporary. Still need to define how we handle errors on the UI.
        this.$notifications.error({ text: 'There was an issue fetching plans. Please try again.', tinted: true, persistent: true })
      },
      fetchPolicy: CACHE_FIRST,
    },
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      // Initialize Apollo properites
      userId: INITIAL_USER_ID,
      fiduciaries: [],
      providers: [],
      programs: [],
      products: [],
      menus: [],
      planStatuses: [],
      plansData: { plans: [] },
      plansDataNextPage: null, // Property not actually read from. Apollo needs a property to set query result to.
      plansDataPrevPage: null, // Property not actually read from. Apollo needs a property to set query result to.
      plansCountData: { count: 0 },
      // End initialize Apollo properties
      selectedView: RoleAccess.selectedView,
      selectedFiduciary: INITIAL_SELECTED_FIDUCIARY,
      selectedProvider: INITIAL_SELECTED_PROVIDER,
      selectedProgram: INITIAL_SELECTED_PROGRAM,
      selectedProduct: INITIAL_SELECTED_PRODUCT,
      selectedMenu: INITIAL_SELECTED_MENU,
      selectedStatus: INITIAL_SELECTED_STATUS,
      selectedEndDate: INITIAL_SELECTED_END_DATE,
      selectedUpdateRange: INITIAL_SELECTED_UPDATED_RANGE,
      selectedUpdateRangeFilter: INITIAL_SELECTED_UPDATED_RANGE,
      selectedPlanId: INITIAL_SEARCHED_PLAN_ID, // Property that is actually passed to API. Should only be set when user hits "enter" in input.
      searchedPlanId: INITIAL_SEARCHED_PLAN_ID, // Property used by search input's v-model. Should always reflect current value of input.
      tableDataPaginationData: {
        pageSize: NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: 1,
        firstItem: 1,
      },
      displayAdditionalDataPointsPopover: false,
      additionalDataPoints: {
        [DataStates.PLAN]: initializeAdditionalDataPointConfig(PLAN_TABLE_COLUMN_CONFIG),
      },
      errorLoadingData: false,
      activeCellData: INITIAL_ACTIVE_CELL_DATA,
      triggerPopover: INITIAL_TRIGGER_POPOVER_HANDLER,
      filtersPlaceholder: ALL_PLACEHOLDER,
      displayDownloadPopover: false,
      authErrorMessage: null,
      inputNewQuickLinkName: EMPTY_NEW_QUICK_LINK_NAME,
      toggleModal: false,
    }
  },
  computed: {
    filtersLoading () {
      return this.$apollo.queries.fiduciaries.loading ||
        this.$apollo.queries.providers.loading ||
        this.$apollo.queries.programs.loading ||
        this.$apollo.queries.products.loading ||
        this.$apollo.queries.menus.loading ||
        this.$apollo.queries.planStatuses.loading
    },
    plansLoading () {
      return this.$apollo.queries.plansData.loading || this.$apollo.queries.plansCountData.loading
    },
    emptyStateValidation () {
      return !this.plansLoading && !this.filtersLoading && this.plans.length === 0
    },
    tableDataValidation () {
      return !this.plansLoading && this.plans.length > 0 && !this.errorLoadingData
    },
    isViewChanged () {
      return sessionStorage.getItem('isViewChanged') !== null ? JSON.parse(sessionStorage.getItem('isViewChanged')) : null
    },
    isProvidersFilterSet () {
      return sessionStorage.getItem(`selectedProvider___${DATA_PLANS_NAME}`) !== null &&
        !isEmpty(JSON.parse(sessionStorage.getItem(`selectedProvider___${DATA_PLANS_NAME}`)))
    },
    filterFiduciaryOptions () {
      return this.fiduciaries.map(fiduciary => ({ text: fiduciary.name, value: fiduciary.code }))
    },
    filterProviderOptions () {
      return [...this.providers.map(({ code }) => ({ text: code, value: code }))]
    },
    filterProgramOptions () {
      return [...this.programs.map(({ code }) => ({ text: code, value: code }))]
    },
    filterProductOptions () {
      return [...this.products.map(({ code }) => ({ text: code, value: code }))]
    },
    filterMenuOptions () {
      return [...this.menus.map(({ name }) => ({ text: name, value: name }))]
    },
    filterStatusOptions () {
      return [...this.planStatuses.map(({ name }) => ({ text: name, value: name }))]
    },
    plansFilters () {
      const [start, end] = this.selectedUpdateRangeFilter
      const providersByView = [...this.providers.map(({ code }) => code)] // <--- list of providers depending on view and role selection
      return {
        ...(this.selectedPlanId ? { planCode: this.selectedPlanId } : {}),
        ...(this.selectedFiduciary?.length > 0 ? { fiduciaryCode: this.selectedFiduciary } : {}),
        ...(this.selectedProvider?.length > 0 ? { providerCode: this.selectedProvider } : { providerCode: providersByView }),
        ...(this.selectedProgram.length > 0 ? { programCode: this.selectedProgram } : {}),
        ...(this.selectedProduct.length > 0 ? { productCode: this.selectedProduct } : {}),
        ...(this.selectedMenu.length > 0 ? { menuName: this.selectedMenu } : {}),
        ...(this.selectedStatus.length > 0 ? { planStatus: this.selectedStatus } : {}),
        ...(this.selectedEndDate ? { endDate: formatIsoFromDateString(this.selectedEndDate, DATE_FORMAT) } : {}),
        ...(start && end ? { updatedRangeStart: formatIsoFromDateString(start, DATE_FORMAT), updatedRangeEnd: formatIsoFromDateString(end, DATE_FORMAT) } : {}),
      }
    },
    plansPagination () {
      return {
        page: this.tableDataPaginationData.page,
        limit: this.tableDataPaginationData.pageSize,
      }
    },
    filteredAdditionalDataPoints () {
      return Object.entries(this.additionalDataPoints).reduce((options, [key, value]) => {
        if (DataStates.PLAN.includes(key)) {
          return [ ...options, ...value ]
        } else {
          return options
        }
      }, [])
    },
    filteredAdditionalDataPointValues () {
      return this.filteredAdditionalDataPoints
        .filter(datapoint => datapoint.selected)
        .map(datapoint => datapoint.id)
    },
    totalNumberOfPlans () {
      return this.plansCountData?.count
    },
    plans () {
      return this.plansData?.plans
    },
    tableHeaders () {
      const headersConfig = [
        ...PLAN_TABLE_COLUMN_CONFIG,
      ]
      return headersConfig
        .filter(({ id, display }) => display === ColumnDisplayStates.ALWAYS || this.filteredAdditionalDataPointValues.includes(id))
        .map(({ id, name, width }) => ({ fieldName: id, text: name, width }))
    },
    tableData () {
      return this.plans.reduce((rows, plan, planIndex) => {
        const planRow = this._constructPlanDataRow(plan, planIndex)
        return [
          ...rows,
          planRow,
        ]
      }, [])
    },
    tablePaginationConfig () {
      return {
        pageSizes: NUMBER_OF_ROWS_PER_PAGE_OPTIONS,
        pageSize: this.tableDataPaginationData.pageSize || NUMBER_OF_ROWS_PER_PAGE_DEFAULT,
        page: this.tableDataPaginationData.page,
        totalItems: this.totalNumberOfPlans,
        showItemsInfo: true,
        showItemsSelect: true,
      }
    },
    mappedFiduciaries () {
      return this._constructCodeToNameMap(this.fiduciaries)
    },
    disableQuickLinkSaveBtn () {
      return this.inputNewQuickLinkName === EMPTY_NEW_QUICK_LINK_NAME
    },
  },
  watch: {
    plansFilters () {
      // Any changes to the filters should navigate to page 1
      this.tableDataPaginationData.page = 1
      this.tableDataPaginationData.firstItem = 1
    },
    selectedUpdateRange (newValue, oldValue) {
      // Only want to trigger a new API request if the new selected range contains both or neither start and end dates
      if (newValue.length !== 1) {
        this.selectedUpdateRangeFilter = newValue
      } else {
        this.selectedUpdateRangeFilter = oldValue
      }
    },
  },
  mounted () {
    this.getLiveProviders()
  },
  methods: {
    toggleQuickLinkModal () {
      this.toggleModal = !this.toggleModal
    },
    fetchAdditonalPlansDataPages () {
      // Abstract "sibling" page fetching logic to mixin/hook if needed elsewhere
      const query = (property, options) => this.$apollo.addSmartQuery(property, options)
      const options = (page) => ({
        query: GET_PLANS,
        variables: {
          filters: this.plansFilters,
          pagination: { ...this.plansPagination, page },
        },
        update: data => data.plans,
        fetchPolicy: CACHE_FIRST,
      })

      // Fetch the previous and next page of data
      const { page: currentPage } = this.plansPagination
      query('plansDataNextPage', options(currentPage + 1))

      if (currentPage > 1) {
        query('plansDataPrevPage', options(currentPage - 1))
      }
    },
    handleUnauthorizedError (error) {
      this.errorLoadingData = true
      // Error handling for unauthorized users
      this.authErrorMessage = error?.graphQLErrors[0].message || error?.message
      this.$notifications.error({ text: this.authErrorMessage, tinted: true, persistent: true })
    },
    async downloadPlansData (levelType = 'PLAN') {
      this.$notifications.informational({ title: 'Your download has been initiated!', text: 'This could take a few seconds.' })

      try {
        const { ...filters } = this.plansFilters
        Object.entries(filters).forEach(([key, values]) => {
          filters[key] = values.join()
        })
        const plansOptions = {
          includeFundLevel: levelType === 'FUNDS',
          includeComplianceLevel: levelType === 'COMPLIANCE',
        }
        const config = {
          params: { ...filters, ...plansOptions },
          responseType: 'blob',
          withCredentials: true, // Pass cookies
        }
        const response = await this.$fetch.get(`${process.env.VUE_APP_API_URL}/download/plans`, { config })
        const filename = response.headers['content-disposition'].split('filename=')[1]
        this.$file.downloadBlobFile(filename, response.data)
        this.$notifications.success({ text: 'Your download has completed!', tinted: true })
      } catch (err) {
        this.$notifications.error({ text: 'There was an issue downloading the plans data. Please try again.', tinted: true, persistent: true })
      }
    },
    async getLiveProviders () {
      // If a user switch to Test/Live Views or there are providers in session storage, provider selection will be cleared
      if (this.isViewChanged || ((this.selectedView !== RadarViews.ALL) && !this.isProvidersFilterSet)) {
        this.selectedProvider = EMPTY_SELECTED_PROVIDER
        sessionStorage.setItem('isViewChanged', false)
        return
      }
      if ((this.selectedView === RadarViews.ALL) && !this.isProvidersFilterSet) {
        // Fetch live providers when selected view is All and is not redirected from a Quick Link
        const providerResponse = await this.$apollo.query({
          query: GET_LIVE_PROVIDERS,
          error (error) {
            this.handleUnauthorizedError(error)
          },
        })
        const liveProviderList = [...providerResponse.data.liveProviders.map(({ code }) => code)]
        // Set live providers by default only for the first time when no filter is present in session storage
        if (this.selectedProvider === INITIAL_SELECTED_PROVIDER || EMPTY_SELECTED_PROVIDER) {
          this.selectedProvider = liveProviderList
        }
      }
    },
    clearFilters () {
      this.selectedFiduciary = EMPTY_SELECTED_FIDUCIARY
      this.selectedProvider = EMPTY_SELECTED_PROVIDER
      this.selectedProgram = INITIAL_SELECTED_PROGRAM
      this.selectedProduct = INITIAL_SELECTED_PRODUCT
      this.selectedMenu = INITIAL_SELECTED_MENU
      this.selectedStatus = INITIAL_SELECTED_STATUS
      this.selectedEndDate = INITIAL_SELECTED_END_DATE
      this.selectedUpdateRange = INITIAL_SELECTED_UPDATED_RANGE
      this.selectedPlanId = INITIAL_SEARCHED_PLAN_ID
      this.searchedPlanId = INITIAL_SEARCHED_PLAN_ID
    },
    handleSearch () {
      this.selectedPlanId = this.searchedPlanId
    },
    handleTablePageChange (data) {
      this.tableDataPaginationData = data
      // Scroll to "top" of page
      this.$refs['data-plans-filters'].$el.scrollIntoView()
    },
    _constructPlanDataRow (plan, planIndex) {
      return {
        id: `plan-${plan.planId}-${generateRandomId()}`,
        planId: plan.planCode,
        planTableId: plan.planId,
        planName: plan.planName || '—',
        planProgram: plan.programCode || '—',
        planProduct: plan.productCode || '—',
        planMenu: plan.menuName || '—',
        planProvider: plan.providerCode || '—',
        planFiduciary: this.mappedFiduciaries[plan.fiduciaryCode] || '—',
        planEndDate: plan.endDate ? formatDateFromIsoString(plan.endDate, DATE_FORMAT) : '—',
        planStartDate: plan.startDate ? formatDateFromIsoString(plan.startDate, DATE_FORMAT) : '—',
        planStatus: plan.status || '—',
        planProcessedDate: plan.processedDate ? formatDateFromIsoString(plan.processedDate, DATE_FORMAT) : '—',
        planFundedDate: plan.fundedDate ? formatDateFromIsoString(plan.fundedDate, DATE_FORMAT) : '—',
        class: 'data-plans__table-row data-plans__table-row--primary',
        // Custom "zebraing" so all rows of the same plan are grouped together
        ...(planIndex % 2 ? { style: { backgroundColor: BACKGROUND_COLOR_VAR } } : {}),
      }
    },
    _constructFundDataRow (fund, planId, planIndex) {
      return {
        id: `plan-${planId}-fund-${fund.fundId}-${generateRandomId()}`,
        fundCode: fund.clientFundCode || '—',
        fundName: fund.fundName || '—',
        fundSecId: fund.secId || '—',
        fundTicker: fund.ticker || '—',
        fundCusip: fund.cusip || '—',
        fundId: fund.fundId,
        fundDataStatus: fund.dataStatus || '—',
        fundMorningstarCategory: fund.morningstarCategory || '—',
        fundStatus: fund.fundStatus || '—',
        fundFiduciaryCategory: fund.fiduciaryCategory || '—',
        fundSeriesId: fund.seriesId || '—',
        fundIsUniversePreferred: fund.isUniversePreferred ?? '—',
        fundClientDisplayName: fund.clientDisplayName || '—',
        fundPendingStatus: fund.pendingFundStatus || '—',
        fundPendingFiduciaryCategory: fund.pendingFiduciaryCategory || '—',
        fundPendingSeriesId: fund.pendingSeriesId || '—',
        class: 'data-plans__table-row data-plans__table-row--secondary',
        // Custom "zebraing" so all rows of the same plan are grouped together
        ...(planIndex % 2 ? { style: { backgroundColor: BACKGROUND_COLOR_VAR } } : {}),
      }
    },
    _constructComplianceDataRow (complianceError, planId, planIndex) {
      return {
        id: `plan-${planId}-error-${complianceError.ruleId}-${generateRandomId()}`,
        complianceFundCode: complianceError.clientFundCode || '—',
        complianceFirstSuccessDate: complianceError.firstSuccessDate ? formatDateFromIsoString(complianceError.firstSuccessDate, DATE_FORMAT) : '—',
        complianceTerminationDate: complianceError.terminationDate ? formatDateFromIsoString(complianceError.terminationDate, DATE_FORMAT) : '—',
        complianceDeadlineDate: complianceError.deadlineDate ? formatDateFromIsoString(complianceError.deadlineDate, DATE_FORMAT) : '—',
        complianceErrorCode: complianceError.ruleId || '—',
        complianceErrorMessage: complianceError.errorMessage || '—',
        class: 'data-plans__table-row data-plans__table-row--secondary',
        // Custom "zebraing" so all rows of the same plan are grouped together
        ...(planIndex % 2 ? { style: { backgroundColor: BACKGROUND_COLOR_VAR } } : {}),
      }
    },
    _constructCodeToNameMap (array) {
      return array.reduce((map, item) => {
        map[item.code] = item.name
        return map
      }, {})
    },
    toggleActionButtonPopover ({ cellData, triggerPopover }) {
      this.activeCellData = cellData
      this.triggerPopover = triggerPopover
      if (!this.$refs.popoverRef.isVisible) {
        this.$refs.popoverRef.show()
      } else {
        this.$refs.popoverRef.hide()
      }
    },
    openDownloadPopover () {
      this.displayDownloadPopover = true
    },
    async createNewQuickLink () {
      this.toggleModal = !this.toggleModal

      await this.$apollo.mutate({
        mutation: CREATE_DASHBOARD_QUICK_LINK,
        variables: {
          options: {
            userId: this.userId.id,
            quickLinkName: this.inputNewQuickLinkName,
            quickLinkData: this.getSessionStorageFiltersData(),
            page: PAGE_NAME,
            selectedView: this.selectedView,
          },
        },
      }).then(() => {
        this.$notifications.success({ text: `${this.inputNewQuickLinkName} has been saved.`, tinted: true })
        this.closeNewQuickLinkModal()
      })
    },
    closeNewQuickLinkModal () {
      this.toggleModal = false
      this.inputNewQuickLinkName = EMPTY_NEW_QUICK_LINK_NAME
    },
  },
}
</script>

<style lang="scss" scoped>
// the top must be initialized to avoid style errors
.data-plans-popover, .mds-tooltip___radar {
  top: 0;
}

.download-popover {
  z-index: 3;
}

.data-plans {
  --color--row-background-secondary: #{$mds-background-color-light-gray};
}

.data-plans__filters {
  margin-bottom: $mds-space-4-x;
}

.data-plans__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: $mds-space-4-x;
}

.data-plans__empty {
  align-self: center;
  margin: 0 auto;
}

.content-container__action {
  .data-plans__search-field {
    margin-left: 1rem;
  }
}

.data-plans__filters-section {
  padding: $mds-space-2-and-a-half-x $mds-space-3-x;
  border-radius: $mds-border-radius-panel;
  margin-bottom: $mds-space-3-and-a-half-x;
}

.data-plans__save-filters{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: $mds-space-3-x;
    p {
      margin: 0;
    }
}
</style>

<style lang="scss" module>
// MDS overrides
:global(.data-plans__filters) {
  .mds-label {
    flex: 1 0 0; // Ensure all filters are the same width
  }
  .mds-fieldset__horizontal > .mds-label {
    flex: 1 0 0;
    margin-bottom: 0;
  }
  .mds-fieldset__horizontal {
    .mds-combo-box {
      .mds-combo-box__result-list {
        z-index: 9999;
      }
    }
  }
  .mds-fieldset {
    flex-grow: 2;
    margin-bottom: $mds-space-2-x;
  }
}
// the top and left must be initialized to avoid style errors
:global(.mds-tooltip___radar) {
  top: 0;
  left: 0;
}

:global(.content-container__action) {
  display: flex;
  flex-flow: row nowrap;
}

:global(.data-plans__additional-data-points--multiple-columns) {
  .mds-fieldset {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
    grid-template-rows: repeat(11, 1fr); // Multiple columns with a max of 11 items per column
  }
}

:global(.data-plans__table-row) {
  .data-plans__table-row--secondary {
    .mds-data-table__cell {
      border-top: none; // Remove borders from "fund" and "compliance" table rows
    }
  }
}

:global(.data-plans__empty) {
  .mds-empty-state__icon > * {
    max-width: 100%;
  }
}
</style>
